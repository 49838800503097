exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-discography-tsx": () => import("./../../../src/pages/discography.tsx" /* webpackChunkName: "component---src-pages-discography-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-categories-template-tsx": () => import("./../../../src/templates/CategoriesTemplate.tsx" /* webpackChunkName: "component---src-templates-categories-template-tsx" */),
  "component---src-templates-discography-template-tsx": () => import("./../../../src/templates/DiscographyTemplate.tsx" /* webpackChunkName: "component---src-templates-discography-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/PostTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-record-list-template-tsx": () => import("./../../../src/templates/RecordListTemplate.tsx" /* webpackChunkName: "component---src-templates-record-list-template-tsx" */),
  "component---src-templates-record-template-tsx": () => import("./../../../src/templates/RecordTemplate.tsx" /* webpackChunkName: "component---src-templates-record-template-tsx" */),
  "component---src-templates-song-template-tsx": () => import("./../../../src/templates/SongTemplate.tsx" /* webpackChunkName: "component---src-templates-song-template-tsx" */),
  "component---src-templates-staff-arranger-template-tsx": () => import("./../../../src/templates/staff/ArrangerTemplate.tsx" /* webpackChunkName: "component---src-templates-staff-arranger-template-tsx" */),
  "component---src-templates-staff-lyric-writer-template-tsx": () => import("./../../../src/templates/staff/LyricWriterTemplate.tsx" /* webpackChunkName: "component---src-templates-staff-lyric-writer-template-tsx" */),
  "component---src-templates-staff-singer-template-tsx": () => import("./../../../src/templates/staff/SingerTemplate.tsx" /* webpackChunkName: "component---src-templates-staff-singer-template-tsx" */),
  "component---src-templates-staff-song-writer-template-tsx": () => import("./../../../src/templates/staff/SongWriterTemplate.tsx" /* webpackChunkName: "component---src-templates-staff-song-writer-template-tsx" */),
  "component---src-templates-tags-template-tsx": () => import("./../../../src/templates/TagsTemplate.tsx" /* webpackChunkName: "component---src-templates-tags-template-tsx" */)
}

